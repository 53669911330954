<template>
  <div class="header">
    <img src="../assets/rainbow_tech_logo.png" alt="" srcset="">
  </div>
</template>

<script>
export default {
  name: 'TheHeader',
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 114px;
  background: #0F0F10;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }
}
</style>
