<template>
  <div class="content">
    <div class="image-block">
      <img src="../assets/rainbow_tech_image.jpg" alt="" srcset="">
    </div>
    <div class="text-block">
      <div class="title">
        <svg width="412" height="48" viewBox="0 0 412 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M61.875 1.3125L50.25 47H39.2188L33.0312 23C32.9062 22.5417 32.7396 21.8438 32.5312 20.9062C32.3229 19.9688 32.1042 18.9479 31.875 17.8438C31.6458 16.7188 31.4375 15.6667 31.25 14.6875C31.0833 13.6875 30.9688 12.8958 30.9062 12.3125C30.8438 12.8958 30.7188 13.6771 30.5312 14.6562C30.3646 15.6354 30.1667 16.6771 29.9375 17.7812C29.7292 18.8854 29.5208 19.9167 29.3125 20.875C29.1042 21.8333 28.9375 22.5625 28.8125 23.0625L22.6562 47H11.6562L0 1.3125H9.53125L15.375 26.25C15.5417 27 15.7292 27.8958 15.9375 28.9375C16.1667 29.9792 16.3854 31.0729 16.5938 32.2188C16.8229 33.3438 17.0208 34.4375 17.1875 35.5C17.375 36.5417 17.5104 37.4479 17.5938 38.2188C17.6979 37.4271 17.8333 36.5104 18 35.4688C18.1667 34.4062 18.3438 33.3333 18.5312 32.25C18.7396 31.1458 18.9479 30.125 19.1562 29.1875C19.3646 28.25 19.5521 27.4896 19.7188 26.9062L26.375 1.3125H35.5312L42.1875 26.9062C42.3333 27.4688 42.5 28.2292 42.6875 29.1875C42.8958 30.125 43.1042 31.1458 43.3125 32.25C43.5208 33.3542 43.7083 34.4375 43.875 35.5C44.0625 36.5417 44.1979 37.4479 44.2812 38.2188C44.4271 37.1771 44.625 35.9167 44.875 34.4375C45.1458 32.9375 45.4271 31.4479 45.7188 29.9688C46.0312 28.4896 46.3021 27.25 46.5312 26.25L52.3438 1.3125H61.875Z" fill="#DB3939"/>
          <path d="M105.062 47H95.4062V27.2812H77.3125V47H67.625V1.3125H77.3125V19.2188H95.4062V1.3125H105.062V47Z" fill="#E78F3E"/>
          <path d="M158.094 24.0938C158.094 27.6146 157.656 30.8229 156.781 33.7188C155.906 36.5938 154.573 39.0729 152.781 41.1562C151.01 43.2396 148.76 44.8438 146.031 45.9688C143.302 47.0729 140.073 47.625 136.344 47.625C132.615 47.625 129.385 47.0729 126.656 45.9688C123.927 44.8438 121.667 43.2396 119.875 41.1562C118.104 39.0729 116.781 36.5833 115.906 33.6875C115.031 30.7917 114.594 27.5729 114.594 24.0312C114.594 19.3021 115.365 15.1875 116.906 11.6875C118.469 8.16667 120.865 5.4375 124.094 3.5C127.323 1.5625 131.427 0.59375 136.406 0.59375C141.365 0.59375 145.438 1.5625 148.625 3.5C151.833 5.4375 154.208 8.16667 155.75 11.6875C157.312 15.2083 158.094 19.3438 158.094 24.0938ZM124.75 24.0938C124.75 27.2812 125.146 30.0312 125.938 32.3438C126.75 34.6354 128.01 36.4062 129.719 37.6562C131.427 38.8854 133.635 39.5 136.344 39.5C139.094 39.5 141.323 38.8854 143.031 37.6562C144.74 36.4062 145.979 34.6354 146.75 32.3438C147.542 30.0312 147.938 27.2812 147.938 24.0938C147.938 19.3021 147.042 15.5312 145.25 12.7812C143.458 10.0312 140.51 8.65625 136.406 8.65625C133.677 8.65625 131.448 9.28125 129.719 10.5312C128.01 11.7604 126.75 13.5312 125.938 15.8438C125.146 18.1354 124.75 20.8854 124.75 24.0938Z" fill="#EBD176"/>
          <path d="M240.312 1.3125L228.688 47H217.656L211.469 23C211.344 22.5417 211.177 21.8438 210.969 20.9062C210.76 19.9688 210.542 18.9479 210.312 17.8438C210.083 16.7188 209.875 15.6667 209.688 14.6875C209.521 13.6875 209.406 12.8958 209.344 12.3125C209.281 12.8958 209.156 13.6771 208.969 14.6562C208.802 15.6354 208.604 16.6771 208.375 17.7812C208.167 18.8854 207.958 19.9167 207.75 20.875C207.542 21.8333 207.375 22.5625 207.25 23.0625L201.094 47H190.094L178.438 1.3125H187.969L193.812 26.25C193.979 27 194.167 27.8958 194.375 28.9375C194.604 29.9792 194.823 31.0729 195.031 32.2188C195.26 33.3438 195.458 34.4375 195.625 35.5C195.812 36.5417 195.948 37.4479 196.031 38.2188C196.135 37.4271 196.271 36.5104 196.438 35.4688C196.604 34.4062 196.781 33.3333 196.969 32.25C197.177 31.1458 197.385 30.125 197.594 29.1875C197.802 28.25 197.99 27.4896 198.156 26.9062L204.812 1.3125H213.969L220.625 26.9062C220.771 27.4688 220.938 28.2292 221.125 29.1875C221.333 30.125 221.542 31.1458 221.75 32.25C221.958 33.3542 222.146 34.4375 222.312 35.5C222.5 36.5417 222.635 37.4479 222.719 38.2188C222.865 37.1771 223.062 35.9167 223.312 34.4375C223.583 32.9375 223.865 31.4479 224.156 29.9688C224.469 28.4896 224.74 27.25 224.969 26.25L230.781 1.3125H240.312Z" fill="#4BCF2A"/>
          <path d="M272.375 47H246.062V1.3125H272.375V9.25H255.75V19.2812H271.219V27.2188H255.75V39H272.375V47Z" fill="#25A6CF"/>
          <path d="M326.531 47L323.219 36.125H306.562L303.25 47H292.812L308.938 1.125H320.781L336.969 47H326.531ZM320.906 28L317.594 17.375C317.385 16.6667 317.104 15.7604 316.75 14.6562C316.417 13.5312 316.073 12.3958 315.719 11.25C315.385 10.0833 315.115 9.07292 314.906 8.21875C314.698 9.07292 314.406 10.1354 314.031 11.4062C313.677 12.6562 313.333 13.8438 313 14.9688C312.667 16.0938 312.427 16.8958 312.281 17.375L309 28H320.906Z" fill="#2E4CB7"/>
          <path d="M356.062 1.3125C360.208 1.3125 363.625 1.8125 366.312 2.8125C369.021 3.8125 371.031 5.32292 372.344 7.34375C373.656 9.36458 374.312 11.9167 374.312 15C374.312 17.0833 373.917 18.9062 373.125 20.4688C372.333 22.0312 371.292 23.3542 370 24.4375C368.708 25.5208 367.312 26.4062 365.812 27.0938L379.25 47H368.5L357.594 29.4688H352.438V47H342.75V1.3125H356.062ZM355.375 9.25H352.438V21.5938H355.562C358.771 21.5938 361.062 21.0625 362.438 20C363.833 18.9167 364.531 17.3333 364.531 15.25C364.531 13.0833 363.781 11.5417 362.281 10.625C360.802 9.70833 358.5 9.25 355.375 9.25Z" fill="#6536EC"/>
          <path d="M411.312 47H385V1.3125H411.312V9.25H394.688V19.2812H410.156V27.2188H394.688V39H411.312V47Z" fill="#C844DE"/>
        </svg>
      </div>
      <div class="text">
        At Rainbow Tech Limited, we are passionate about gaming and committed to pushing the boundaries of the industry. Our talented team of designers, artists, programmers, and producers work together to create games that are both visually stunning and technically advanced.
        <br />
        <br />
        Thank you for choosing Rainbow Tech Limited as your gaming partner. We look forward to bringing you many more exciting and unforgettable gaming experiences in the years to come.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheContent',
}
</script>

<style lang="scss" scoped>
.content {  
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 32px 26px;

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
    padding: 54px 0;
  }

  .image-block {
    width: 60%;

    @media only screen and (max-width: 1300px) {
      width: 100%;
    }
  }

  .text-block {
    width: 40%;
    padding: 22px;

    @media only screen and (max-width: 1300px) {
      width: calc(100% - 68px);
      padding: 40px 68px;
    }
  }
  .title {
    text-align: left;
    margin-bottom: 40px;
    
    svg {
      max-width: 100%;
      display: block;
    }


    // @media only screen and (min-width: 751px) and (max-width: 1300px) {
    //   margin-top: 80px;
    //   font-size: 80px;
    // }

    // @media only screen and (max-width: 750px) {
    //   margin-top: 40px;
    //   font-size: 40px;    
    // }
  }

  .text {
    text-align: left;
    font-weight: 700;
    font-size: 22px;
    line-height: 227%;
  }

  img {
    max-width: 100%;
  }
}
</style>
