<template>
  <div class="footer">
    © {{ currentYear }} Rainbow Tech Limited.  All Rights Reserved.
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }

}
</script>

<style scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 114px;
  background: #0F0F10;

  font-weight: 400;
  font-size: 22px;
}
</style>
